type EmtakCode = {
  code: string;
  description: string;
};

// Obtained from https://ariregister.rik.ee/eng/emtak_search
const emtak: EmtakCode[] = [
  {
    code: '01111',
    description: 'Growing of cereals, other than rice, leguminous crops and oil seeds'
  },
  {
    code: '01121',
    description: 'Growing of rice'
  },
  {
    code: '01131',
    description: 'Growing of vegetables and melons, roots and tubers'
  },
  {
    code: '01141',
    description: 'Growing of sugar cane'
  },
  {
    code: '01151',
    description: 'Growing of tobacco'
  },
  {
    code: '01161',
    description: 'Growing of fibre crops'
  },
  {
    code: '01191',
    description: 'Growing of flowers'
  },
  {
    code: '01192',
    description: 'Growing of fodder crops and grasses and other non-perennial crops'
  },
  {
    code: '01211',
    description: 'Growing of grapes'
  },
  {
    code: '01221',
    description: 'Growing of tropical and subtropical fruits'
  },
  {
    code: '01231',
    description: 'Growing of citrus fruits'
  },
  {
    code: '01241',
    description: 'Growing of pome fruits and stone fruits'
  },
  {
    code: '01251',
    description: 'Growing of other tree and bush fruits and nuts'
  },
  {
    code: '01261',
    description: 'Growing of oleaginous fruits'
  },
  {
    code: '01271',
    description: 'Growing of beverage crops'
  },
  {
    code: '01281',
    description: 'Growing of spices, aromatic, drug and pharmaceutical crops'
  },
  {
    code: '01291',
    description: 'Growing of other perennial crops'
  },
  {
    code: '01301',
    description: 'Plant propagation'
  },
  {
    code: '01411',
    description: 'Raising of dairy cattle'
  },
  {
    code: '01421',
    description: 'Raising of other cattle and buffaloes'
  },
  {
    code: '01431',
    description: 'Raising of horses and other equines'
  },
  {
    code: '01441',
    description: 'Raising of camels and camelids'
  },
  {
    code: '01451',
    description: 'Raising of sheep and goats'
  },
  {
    code: '01461',
    description: 'Raising of swine and pigs'
  },
  {
    code: '01471',
    description: 'Raising of poultry'
  },
  {
    code: '01489',
    description: 'Raising of other animals'
  },
  {
    code: '01481',
    description: 'Bee keeping'
  },
  {
    code: '01501',
    description: 'Mixed farming'
  },
  {
    code: '01611',
    description: 'Support activities for crop production'
  },
  {
    code: '01621',
    description: 'Support activities for animal production'
  },
  {
    code: '01631',
    description: 'Post-harvest crop activities'
  },
  {
    code: '01632',
    description: 'Seed processing for propagation'
  },
  {
    code: '01701',
    description: 'Hunting, trapping and related service activities'
  },
  {
    code: '02101',
    description: 'Silviculture and other forestry activities'
  },
  {
    code: '02201',
    description: 'Logging'
  },
  {
    code: '02202',
    description: 'Firewood production'
  },
  {
    code: '02301',
    description: 'Gathering of wild growing non-wood products'
  },
  {
    code: '02401',
    description: 'Support services to forestry'
  },
  {
    code: '03111',
    description: 'Marine fishing'
  },
  {
    code: '03121',
    description: 'Freshwater fishing'
  },
  {
    code: '03211',
    description: 'Marine aquaculture'
  },
  {
    code: '03221',
    description: 'Freshwater aquaculture'
  },
  {
    code: '03301',
    description: 'Support activities for fishing and aquaculture'
  },
  {
    code: '05101',
    description: 'Mining of hard coal'
  },
  {
    code: '05201',
    description: 'Mining of lignite'
  },
  {
    code: '06101',
    description: 'Extraction of crude petroleum and mining of oil shale'
  },
  {
    code: '06201',
    description: 'Extraction of natural gas'
  },
  {
    code: '07101',
    description: 'Mining of iron ores'
  },
  {
    code: '07211',
    description: 'Mining of uranium and thorium ores'
  },
  {
    code: '07291',
    description: 'Mining of other non-ferrous metal ores'
  },
  {
    code: '08111',
    description: 'Quarrying of ornamental stone, limestone, gypsum, slate and other stone'
  },
  {
    code: '08121',
    description: 'Operation of gravel and sand pits and mining of clay and kaolin'
  },
  {
    code: '08122',
    description: 'Manufacture of crushed stone'
  },
  {
    code: '08911',
    description: 'Mining of chemical and fertiliser minerals'
  },
  {
    code: '08921',
    description: 'Extraction of peat'
  },
  {
    code: '08931',
    description: 'Extraction of salt'
  },
  {
    code: '08991',
    description: 'Other mining and quarrying n.e.c.'
  },
  {
    code: '09101',
    description: 'Support activities for petroleum and natural gas extraction'
  },
  {
    code: '09901',
    description: 'Support activities for other mining and quarrying'
  },
  {
    code: '10111',
    description: 'Processing and preserving of meat, except of poultry meat'
  },
  {
    code: '10121',
    description: 'Processing and preserving of poultry meat, including the processing of feathers'
  },
  {
    code: '10131',
    description: 'Production of meat and poultry meat products'
  },
  {
    code: '10201',
    description: 'Processing and preserving of fish, crustaceans and molluscs'
  },
  {
    code: '10311',
    description: 'Processing and preserving of potatoes'
  },
  {
    code: '10321',
    description: 'Manufacture of fruit and vegetable juice'
  },
  {
    code: '10391',
    description: 'Other processing and preserving of fruit and vegetables'
  },
  {
    code: '10411',
    description: 'Manufacture of oils and fats'
  },
  {
    code: '10421',
    description: 'Manufacture of margarine and similar edible fats'
  },
  {
    code: '10511',
    description: 'Processing of raw milk, production of fresh cream'
  },
  {
    code: '10512',
    description: 'Manufacture of cheese and curd'
  },
  {
    code: '10519',
    description: 'Manufacture of other dairy products, including butter'
  },
  {
    code: '10521',
    description: 'Manufacture of ice cream and other edible ice'
  },
  {
    code: '10611',
    description: 'Manufacture of grain mill products'
  },
  {
    code: '10621',
    description: 'Manufacture of starches and starch products'
  },
  {
    code: '10711',
    description: 'Manufacture of bread and white bread; manufacture of fresh pastry goods and cakes'
  },
  {
    code: '10721',
    description: 'Manufacture of rusks, biscuits, preserved pastries and cakes'
  },
  {
    code: '10731',
    description: 'Manufacture of farinaceous products'
  },
  {
    code: '10811',
    description: 'Manufacture of sugar'
  },
  {
    code: '10821',
    description: 'Manufacture of cocoa, chocolate and sugar confectionery'
  },
  {
    code: '10831',
    description: 'Processing of tea and coffee'
  },
  {
    code: '10841',
    description: 'Manufacture of condiments and seasonings'
  },
  {
    code: '10851',
    description: 'Manufacture of prepared meals and dishes'
  },
  {
    code: '10861',
    description: 'Manufacture of homogenised food preparations and dietetic food'
  },
  {
    code: '10891',
    description: 'Manufacture of other food products n.e.c.'
  },
  {
    code: '10911',
    description: 'Manufacture of prepared feeds for farm animals'
  },
  {
    code: '10921',
    description: 'Manufacture of prepared pet foods'
  },
  {
    code: '11011',
    description: 'Distilling, rectifying and blending of spirits'
  },
  {
    code: '11021',
    description: 'Manufacture of wine from grape'
  },
  {
    code: '11031',
    description: 'Manufacture of cider and other fermented fruit beverages'
  },
  {
    code: '11041',
    description: 'Manufacture of other non-distilled fermented beverages'
  },
  {
    code: '11051',
    description: 'Manufacture of beer'
  },
  {
    code: '11061',
    description: 'Manufacture of malt'
  },
  {
    code: '11071',
    description: 'Manufacture of soft drinks and bottled waters'
  },
  {
    code: '12001',
    description: 'Manufacture of tobacco products'
  },
  {
    code: '13101',
    description: 'Preparation and spinning of textile fibres'
  },
  {
    code: '13201',
    description: 'Weaving of textiles'
  },
  {
    code: '13301',
    description: 'Finishing of textiles'
  },
  {
    code: '13911',
    description: 'Manufacture of knitted and crocheted fabrics'
  },
  {
    code: '13921',
    description: 'Manufacture of household textiles, such as bedding, towels, curtains, etc.'
  },
  {
    code: '13929',
    description: 'Manufacture of other made-up textile articles'
  },
  {
    code: '13931',
    description: 'Manufacture of carpets and rugs'
  },
  {
    code: '13941',
    description: 'Manufacture of cordage, rope, twine and netting'
  },
  {
    code: '13951',
    description: 'Manufacture of non-wovens and non-woven articles'
  },
  {
    code: '13961',
    description: 'Manufacture of other technical and industrial textiles'
  },
  {
    code: '13991',
    description: 'Manufacture of other textiles n.e.c.'
  },
  {
    code: '14101',
    description: 'Manufacture of knitted and crocheted apparel'
  },
  {
    code: '14211',
    description: 'Manufacture of outerwear'
  },
  {
    code: '14221',
    description: 'Manufacture of underwear'
  },
  {
    code: '14231',
    description: 'Manufacture of workwear'
  },
  {
    code: '14241',
    description: 'Manufacture of leather clothes and fur apparel'
  },
  {
    code: '14299',
    description: 'Manufacture of other wearing apparel and accessories n.e.c.'
  },
  {
    code: '14291',
    description: 'Manufacture of headgear, including of fur'
  },
  {
    code: '15111',
    description: 'Tanning, dressing, dyeing of leather and fur'
  },
  {
    code: '15121',
    description: 'Manufacture of luggage, handbags and the like'
  },
  {
    code: '15122',
    description: 'Manufacture of saddlery and harness'
  },
  {
    code: '15129',
    description: 'Manufacture of other articles of leather and related products, of any material'
  },
  {
    code: '15201',
    description: 'Manufacture of footwear'
  },
  {
    code: '16111',
    description: 'Sawmilling and planing of wood'
  },
  {
    code: '16121',
    description: 'Processing and finishing of wood'
  },
  {
    code: '16211',
    description: 'Manufacture of veneer sheets and plywood'
  },
  {
    code: '16212',
    description: 'Manufacture of particle boards and fibreboard'
  },
  {
    code: '16219',
    description: 'Manufacture of other fibreboard'
  },
  {
    code: '16221',
    description: 'Manufacture of assembled parquet floors'
  },
  {
    code: '16231',
    description: 'Manufacture of prefabricated wooden buildings or elements thereof'
  },
  {
    code: '16239',
    description: 'Manufacture of other builders’ joinery and carpentry of wood'
  },
  {
    code: '16241',
    description: 'Manufacture of wooden containers and pallets'
  },
  {
    code: '16251',
    description: 'Manufacture of doors and windows of wood'
  },
  {
    code: '16261',
    description: 'Manufacture of solid fuels from vegetable biomass'
  },
  {
    code: '16271',
    description: 'Finishing of wooden products'
  },
  {
    code: '16281',
    description: 'Manufacture of wooden articles and ornaments and other wooden products'
  },
  {
    code: '16282',
    description: 'Manufacture of articles of cork, straw and plaiting materials'
  },
  {
    code: '17111',
    description: 'Manufacture of pulp'
  },
  {
    code: '17121',
    description: 'Manufacture of paper and paperboard'
  },
  {
    code: '17211',
    description:
      'Manufacture of corrugated paper, paperboard and containers of paper and paperboard'
  },
  {
    code: '17221',
    description: 'Manufacture of household and sanitary goods and of toilet requisites'
  },
  {
    code: '17231',
    description: 'Manufacture of paper stationery'
  },
  {
    code: '17241',
    description: 'Manufacture of wallpaper'
  },
  {
    code: '17251',
    description: 'Manufacture of other articles of paper and paperboard'
  },
  {
    code: '18111',
    description: 'Printing of newspapers'
  },
  {
    code: '18121',
    description: 'Printing of books'
  },
  {
    code: '18122',
    description: 'Printing of periodicals, advertising materials, forms, etc.'
  },
  {
    code: '18129',
    description: 'Printing n.e.c., including silk-screen printing'
  },
  {
    code: '18131',
    description: 'Pre-press and pre-media services'
  },
  {
    code: '18141',
    description: 'Binding and related services'
  },
  {
    code: '18201',
    description: 'Reproduction of recorded media'
  },
  {
    code: '19101',
    description: 'Manufacture of coke oven products'
  },
  {
    code: '19201',
    description: 'Manufacture of refined petroleum products and fossil fuel products'
  },
  {
    code: '20111',
    description: 'Manufacture of industrial gases'
  },
  {
    code: '20121',
    description: 'Manufacture of dyes and pigments'
  },
  {
    code: '20131',
    description: 'Manufacture of other inorganic basic chemicals'
  },
  {
    code: '20141',
    description: 'Manufacture of other organic basic chemicals'
  },
  {
    code: '20151',
    description: 'Manufacture of fertilisers and nitrogen compounds'
  },
  {
    code: '20161',
    description: 'Manufacture of plastics in primary forms'
  },
  {
    code: '20171',
    description: 'Manufacture of synthetic rubber in primary forms'
  },
  {
    code: '20201',
    description: 'Manufacture of pesticides, disinfectants and other agrochemical products'
  },
  {
    code: '20301',
    description: 'Manufacture of paints, varnishes and similar coatings, printing ink and mastics'
  },
  {
    code: '20411',
    description: 'Manufacture of soap and detergents, cleaning and polishing preparations'
  },
  {
    code: '20421',
    description: 'Manufacture of perfume and toilet preparations'
  },
  {
    code: '20511',
    description: 'Manufacture of liquid biofuels'
  },
  {
    code: '20599',
    description: 'Manufacture of other chemical products n.e.c.'
  },
  {
    code: '20591',
    description: 'Manufacture of explosives'
  },
  {
    code: '20592',
    description: 'Manufacture of glues'
  },
  {
    code: '20593',
    description: 'Manufacture of essential oils'
  },
  {
    code: '20601',
    description: 'Manufacture of man-made fibres'
  },
  {
    code: '21101',
    description: 'Manufacture of basic pharmaceutical products'
  },
  {
    code: '21201',
    description: 'Manufacture of pharmaceutical preparations'
  },
  {
    code: '22111',
    description: 'Manufacture, retreading and rebuilding of rubber tyres and manufacture of tubes'
  },
  {
    code: '22121',
    description: 'Manufacture of other rubber products'
  },
  {
    code: '22211',
    description: 'Manufacture of plastic plates, sheets, tubes and profiles'
  },
  {
    code: '22221',
    description: 'Manufacture of plastic packing goods'
  },
  {
    code: '22231',
    description: 'Manufacture of plastic doors, windows, partitions, etc.'
  },
  {
    code: '22241',
    description: 'Manufacture of plastic sanitary ware (baths, washbasins, etc.)'
  },
  {
    code: '22249',
    description: 'Manufacture of other builders’ ware of plastic'
  },
  {
    code: '22251',
    description: 'Processing and finishing of plastic products'
  },
  {
    code: '22261',
    description: 'Manufacture of other plastic products'
  },
  {
    code: '23111',
    description: 'Manufacture of flat glass'
  },
  {
    code: '23121',
    description: 'Shaping and processing of flat glass'
  },
  {
    code: '23131',
    description:
      'Manufacture of glassware of a kind used for table, indoor or other purposes, except glass containers'
  },
  {
    code: '23132',
    description: 'Manufacture of glass containers'
  },
  {
    code: '23141',
    description: 'Manufacture of glass fibres'
  },
  {
    code: '23151',
    description: 'Manufacture of glass construction materials'
  },
  {
    code: '23159',
    description: 'Manufacture of other glass and technical glassware'
  },
  {
    code: '23201',
    description: 'Manufacture of refractory products'
  },
  {
    code: '23311',
    description: 'Manufacture of ceramic tiles and flags'
  },
  {
    code: '23321',
    description: 'Manufacture of bricks, tiles and construction products, in baked clay'
  },
  {
    code: '23411',
    description: 'Manufacture of ceramic household and ornamental articles'
  },
  {
    code: '23421',
    description: 'Manufacture of ceramic sanitary fixtures'
  },
  {
    code: '23431',
    description: 'Manufacture of ceramic insulators and insulating fittings'
  },
  {
    code: '23441',
    description: 'Manufacture of other technical ceramic products'
  },
  {
    code: '23451',
    description: 'Manufacture of other ceramic products'
  },
  {
    code: '23511',
    description: 'Manufacture of cement'
  },
  {
    code: '23521',
    description: 'Manufacture of lime and plaster'
  },
  {
    code: '23611',
    description: 'Manufacture of prefabricated concrete buildings and components thereof'
  },
  {
    code: '23619',
    description: 'Manufacture of other concrete products for construction purposes'
  },
  {
    code: '23621',
    description: 'Manufacture of plaster products for construction purposes'
  },
  {
    code: '23631',
    description: 'Manufacture of ready-mixed concrete'
  },
  {
    code: '23641',
    description: 'Manufacture of mortars'
  },
  {
    code: '23651',
    description: 'Manufacture of fibre cement'
  },
  {
    code: '23661',
    description: 'Manufacture of other articles of concrete, cement and plaster'
  },
  {
    code: '23701',
    description: 'Manufacture of gravestones, slabs and the like of natural stone'
  },
  {
    code: '23702',
    description: 'Manufacture of building materials and other products of limestone and dolomite'
  },
  {
    code: '23703',
    description: 'Manufacture of products of granite, marble and natural stone'
  },
  {
    code: '23911',
    description: 'Manufacture of abrasive products'
  },
  {
    code: '23991',
    description: 'Manufacture of other non-metallic mineral products n.e.c.'
  },
  {
    code: '24101',
    description: 'Manufacture of basic iron and steel and of ferro-alloys'
  },
  {
    code: '24201',
    description: 'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel'
  },
  {
    code: '24311',
    description: 'Cold drawing of bars'
  },
  {
    code: '24321',
    description: 'Cold rolling of narrow strip'
  },
  {
    code: '24331',
    description: 'Cold forming or folding'
  },
  {
    code: '24341',
    description: 'Cold drawing of wire'
  },
  {
    code: '24411',
    description: 'Precious metals production'
  },
  {
    code: '24421',
    description: 'Aluminium production'
  },
  {
    code: '24431',
    description: 'Lead, zinc and tin production'
  },
  {
    code: '24441',
    description: 'Copper production'
  },
  {
    code: '24451',
    description: 'Other non-ferrous metal production'
  },
  {
    code: '24461',
    description: 'Processing of nuclear fuel'
  },
  {
    code: '24511',
    description: 'Casting of iron'
  },
  {
    code: '24521',
    description: 'Casting of steel'
  },
  {
    code: '24531',
    description: 'Casting of light metals'
  },
  {
    code: '24541',
    description: 'Casting of other non-ferrous metals'
  },
  {
    code: '25111',
    description: 'Manufacture of prefabricated metal buildings'
  },
  {
    code: '25119',
    description: 'Manufacture of other metal structures and parts of structures'
  },
  {
    code: '25121',
    description: 'Manufacture of doors and windows of metal'
  },
  {
    code: '25211',
    description: 'Manufacture of central heating radiators, steam generators and boilers'
  },
  {
    code: '25221',
    description: 'Manufacture of other tanks, reservoirs and containers of metal'
  },
  {
    code: '25301',
    description: 'Manufacture of weapons and ammunition'
  },
  {
    code: '25401',
    description: 'Forging and shaping metal and powder metallurgy'
  },
  {
    code: '25511',
    description: 'Coating of metals'
  },
  {
    code: '25521',
    description: 'Heat treatment of metals'
  },
  {
    code: '25531',
    description: 'Machining of metals'
  },
  {
    code: '25611',
    description: 'Manufacture of cutlery'
  },
  {
    code: '25621',
    description: 'Manufacture of locks and hinges'
  },
  {
    code: '25631',
    description: 'Manufacture of tools'
  },
  {
    code: '25911',
    description: 'Manufacture of steel drums and similar containers'
  },
  {
    code: '25921',
    description: 'Manufacture of light metal packaging'
  },
  {
    code: '25931',
    description: 'Manufacture of wire products, chain and springs'
  },
  {
    code: '25941',
    description: 'Manufacture of fasteners and screw machine products'
  },
  {
    code: '25991',
    description: 'Manufacture of other fabricated metal products n.e.c.'
  },
  {
    code: '26111',
    description: 'Manufacture of electronic components'
  },
  {
    code: '26121',
    description: 'Manufacture of loaded electronic boards'
  },
  {
    code: '26201',
    description: 'Manufacture of computers and peripheral equipment'
  },
  {
    code: '26301',
    description: 'Manufacture of communication equipment'
  },
  {
    code: '26401',
    description: 'Manufacture of consumer electronics'
  },
  {
    code: '26511',
    description: 'Manufacture of instruments and appliances for measuring, testing and navigation'
  },
  {
    code: '26521',
    description: 'Manufacture of watches and clocks'
  },
  {
    code: '26601',
    description: 'Manufacture of irradiation, electromedical and electrotherapeutic equipment'
  },
  {
    code: '26701',
    description:
      'Manufacture of optical instruments, magnetic and optical media and photographic equipment'
  },
  {
    code: '27111',
    description: 'Manufacture of electric motors, generators and transformers'
  },
  {
    code: '27121',
    description: 'Manufacture of electricity distribution and control apparatus'
  },
  {
    code: '27201',
    description: 'Manufacture of batteries and accumulators'
  },
  {
    code: '27311',
    description: 'Manufacture of fibre optic cables'
  },
  {
    code: '27321',
    description: 'Manufacture of other electronic and electric wires and cables'
  },
  {
    code: '27331',
    description: 'Manufacture of wiring devices'
  },
  {
    code: '27401',
    description: 'Manufacture of lighting equipment'
  },
  {
    code: '27511',
    description: 'Manufacture of electric domestic appliances'
  },
  {
    code: '27521',
    description: 'Manufacture of non-electric domestic appliances'
  },
  {
    code: '27901',
    description: 'Manufacture of other electrical equipment'
  },
  {
    code: '28111',
    description: 'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines'
  },
  {
    code: '28121',
    description: 'Manufacture of fluid power equipment'
  },
  {
    code: '28131',
    description: 'Manufacture of other pumps and compressors'
  },
  {
    code: '28141',
    description: 'Manufacture of other taps and valves'
  },
  {
    code: '28151',
    description: 'Manufacture of bearings, gears, gearing and driving elements'
  },
  {
    code: '28211',
    description: 'Manufacture of ovens, furnaces and permanent household heating equipment'
  },
  {
    code: '28221',
    description: 'Manufacture of lifting and handling equipment'
  },
  {
    code: '28231',
    description:
      'Manufacture of office machinery and equipment, except computers and peripheral equipment'
  },
  {
    code: '28241',
    description: 'Manufacture of power-driven hand tools'
  },
  {
    code: '28251',
    description: 'Manufacture of non-domestic air conditioning equipment'
  },
  {
    code: '28291',
    description: 'Manufacture of other general-purpose machinery n.e.c.'
  },
  {
    code: '28301',
    description: 'Manufacture of agricultural machinery'
  },
  {
    code: '28302',
    description: 'Manufacture of forestry machinery'
  },
  {
    code: '28411',
    description: 'Manufacture of metal forming machinery and machine tools for metal work'
  },
  {
    code: '28421',
    description: 'Manufacture of other machine tools'
  },
  {
    code: '28911',
    description: 'Manufacture of machinery for metallurgy'
  },
  {
    code: '28921',
    description: 'Manufacture of machinery for mining, quarrying and construction'
  },
  {
    code: '28931',
    description: 'Manufacture of machinery for food, beverage and tobacco processing'
  },
  {
    code: '28941',
    description: 'Manufacture of machinery for textile, apparel and leather production'
  },
  {
    code: '28951',
    description: 'Manufacture of machinery for paper and paperboard production'
  },
  {
    code: '28961',
    description: 'Manufacture of plastics and rubber machinery'
  },
  {
    code: '28971',
    description: 'Manufacture of additive manufacturing machinery'
  },
  {
    code: '28991',
    description: 'Manufacture of other special-purpose machinery n.e.c.'
  },
  {
    code: '29101',
    description: 'Manufacture of motor vehicles'
  },
  {
    code: '29201',
    description: 'Manufacture of bodies and coachwork for motor vehicles'
  },
  {
    code: '29202',
    description: 'Manufacture of trailers, semi-trailers and containers'
  },
  {
    code: '29311',
    description: 'Manufacture of electrical and electronic equipment for motor vehicles'
  },
  {
    code: '29321',
    description: 'Manufacture of other parts and accessories for motor vehicles'
  },
  {
    code: '30111',
    description: 'Building of civilian ships'
  },
  {
    code: '30112',
    description: 'Building of civilian floating structures'
  },
  {
    code: '30121',
    description: 'Building of pleasure and sporting boats'
  },
  {
    code: '30131',
    description: 'Building of military ships and vessels'
  },
  {
    code: '30201',
    description: 'Manufacture of railway locomotives and rolling stock'
  },
  {
    code: '30311',
    description: 'Manufacture of civilian air and spacecraft and related machinery'
  },
  {
    code: '30321',
    description: 'Manufacture of military air and spacecraft and related machinery'
  },
  {
    code: '30401',
    description: 'Manufacture of military fighting vehicles'
  },
  {
    code: '30911',
    description: 'Manufacture of motorcycles'
  },
  {
    code: '30921',
    description: 'Manufacture of bicycles and invalid carriages'
  },
  {
    code: '30991',
    description: 'Manufacture of other transport equipment n.e.c.'
  },
  {
    code: '31001',
    description: 'Manufacture of furniture, except mattresses'
  },
  {
    code: '31002',
    description: 'Manufacture of mattresses'
  },
  {
    code: '32111',
    description: 'Striking of coins'
  },
  {
    code: '32121',
    description: 'Manufacture of jewellery and related articles'
  },
  {
    code: '32131',
    description: 'Manufacture of imitation jewellery and related articles'
  },
  {
    code: '32201',
    description: 'Manufacture of musical instruments'
  },
  {
    code: '32301',
    description: 'Manufacture of sports goods'
  },
  {
    code: '32401',
    description: 'Manufacture of games and toys'
  },
  {
    code: '32501',
    description: 'Manufacture of dental instruments and supplies'
  },
  {
    code: '32502',
    description: 'Manufacture of glasses, lenses and parts'
  },
  {
    code: '32509',
    description: 'Manufacture of other medical instruments and supplies'
  },
  {
    code: '32911',
    description: 'Manufacture of brooms and brushes'
  },
  {
    code: '32991',
    description: 'Other manufacturing n.e.c.'
  },
  {
    code: '33111',
    description: 'Repair and maintenance of fabricated metal products'
  },
  {
    code: '33121',
    description: 'Repair and maintenance of machinery'
  },
  {
    code: '33131',
    description: 'Repair and maintenance of electronic and optical equipment'
  },
  {
    code: '33141',
    description: 'Repair and maintenance of electrical equipment'
  },
  {
    code: '33151',
    description: 'Repair and maintenance of civilian ships and boats'
  },
  {
    code: '33161',
    description: 'Repair and maintenance of civilian air and spacecraft'
  },
  {
    code: '33171',
    description: 'Repair and maintenance of other civilian transport equipment'
  },
  {
    code: '33181',
    description:
      'Repair and maintenance of military fighting vehicles, ships, boats, air and spacecraft'
  },
  {
    code: '33191',
    description: 'Repair and maintenance of other equipment'
  },
  {
    code: '33201',
    description: 'Installation of industrial machinery and equipment'
  },
  {
    code: '35111',
    description: 'Production of electricity from non-renewable sources'
  },
  {
    code: '35121',
    description: 'Electricity production from waterpower'
  },
  {
    code: '35122',
    description: 'Electricity production from wind power'
  },
  {
    code: '35123',
    description: 'Electricity production from solar energy'
  },
  {
    code: '35129',
    description: 'Other electricity production, including from biomass'
  },
  {
    code: '35131',
    description: 'Transmission of electricity'
  },
  {
    code: '35141',
    description: 'Distribution of electricity'
  },
  {
    code: '35151',
    description: 'Trade of electricity'
  },
  {
    code: '35161',
    description: 'Storage of electricity'
  },
  {
    code: '35211',
    description: 'Manufacture of gas (except biogas)'
  },
  {
    code: '35212',
    description: 'Manufacture of biogas'
  },
  {
    code: '35213',
    description: 'Manufacture of hydrogen'
  },
  {
    code: '35221',
    description: 'Distribution of gaseous fuels through mains'
  },
  {
    code: '35231',
    description: 'Trade of gas through mains'
  },
  {
    code: '35241',
    description: 'Storage of gas as part of network supply services'
  },
  {
    code: '35301',
    description: 'Steam and air conditioning supply'
  },
  {
    code: '35401',
    description: 'Activities of brokers and agents for electric power and natural gas'
  },
  {
    code: '36001',
    description: 'Water collection, treatment and supply'
  },
  {
    code: '36002',
    description: 'Supply of water intended for human consumption'
  },
  {
    code: '37001',
    description: 'Sewerage and wastewater management'
  },
  {
    code: '38111',
    description: 'Collection of non-hazardous waste'
  },
  {
    code: '38121',
    description: 'Collection of hazardous waste'
  },
  {
    code: '38211',
    description: 'Materials recovery'
  },
  {
    code: '38221',
    description: 'Energy recovery'
  },
  {
    code: '38231',
    description: 'Other waste recovery'
  },
  {
    code: '38311',
    description: 'Incineration without energy recovery'
  },
  {
    code: '38321',
    description: 'Landfilling or permanent storage'
  },
  {
    code: '38331',
    description: 'Other waste disposal'
  },
  {
    code: '39001',
    description: 'Remediation activities and other waste management service activities'
  },
  {
    code: '41001',
    description: 'Construction of residential and non-residential buildings'
  },
  {
    code: '42111',
    description: 'Construction of roads and motorways'
  },
  {
    code: '42121',
    description: 'Construction of railways and underground railways'
  },
  {
    code: '42131',
    description: 'Construction of bridges and tunnels'
  },
  {
    code: '42211',
    description: 'Construction of utility projects for fluids'
  },
  {
    code: '42212',
    description: 'Drilling and closure of water wells and boreholes'
  },
  {
    code: '42221',
    description: 'Construction of utility projects for electricity and telecommunications'
  },
  {
    code: '42911',
    description: 'Construction of water projects'
  },
  {
    code: '42991',
    description: 'Construction of other civil engineering projects n.e.c.'
  },
  {
    code: '43111',
    description: 'Demolition'
  },
  {
    code: '43121',
    description: 'Site formation and clearance work'
  },
  {
    code: '43122',
    description: 'Land drainage and land reclamation works'
  },
  {
    code: '43129',
    description: 'Other earth and soil works'
  },
  {
    code: '43131',
    description: 'Test drilling and boring'
  },
  {
    code: '43211',
    description: 'Electrical installation work'
  },
  {
    code: '43212',
    description: 'Installation of signalling systems'
  },
  {
    code: '43213',
    description: 'Installation of telecommunication wirings and antennas'
  },
  {
    code: '43221',
    description:
      'Installation, maintenance and repair of heating, ventilation and air conditioning equipment'
  },
  {
    code: '43222',
    description: 'Installation, maintenance and repair of plumbing and sanitary equipment'
  },
  {
    code: '43223',
    description: 'Construction or installation of masonry stoves, ovens and fireplaces'
  },
  {
    code: '43231',
    description: 'Installation of insulation'
  },
  {
    code: '43241',
    description: 'Other construction installation'
  },
  {
    code: '43311',
    description: 'Plastering'
  },
  {
    code: '43321',
    description: 'Installation of doors, windows and staircases of wood or other materials'
  },
  {
    code: '43329',
    description: 'Other joinery installation'
  },
  {
    code: '43331',
    description: 'Floor and wall covering'
  },
  {
    code: '43341',
    description: 'Painting and glazing'
  },
  {
    code: '43351',
    description: 'Other building completion and finishing'
  },
  {
    code: '43411',
    description: 'Roofing activities'
  },
  {
    code: '43421',
    description: 'Other specialised construction activities in construction of buildings'
  },
  {
    code: '43501',
    description: 'Specialised construction activities in civil engineering'
  },
  {
    code: '43601',
    description: 'Intermediation service activities for specialised construction services'
  },
  {
    code: '43999',
    description: 'Other specialised construction activities'
  },
  {
    code: '43911',
    description: 'Masonry and bricklaying activities'
  },
  {
    code: '43991',
    description:
      'Erecting and dismantling of scaffolds, rental of cranes and other building equipment with an operator'
  },
  {
    code: '46111',
    description:
      'Activities of agents involved in the wholesale of agricultural raw materials, live animals, textile raw materials and semi-finished goods'
  },
  {
    code: '46121',
    description:
      'Activities of agents involved in the wholesale of fuels, ores, metals and industrial chemicals'
  },
  {
    code: '46131',
    description: 'Activities of agents involved in the wholesale of timber and building materials'
  },
  {
    code: '46141',
    description:
      'Activities of agents involved in the wholesale of machinery, industrial equipment, ships and aircraft'
  },
  {
    code: '46151',
    description:
      'Activities of agents involved in the wholesale of furniture, household goods, hardware and ironmongery'
  },
  {
    code: '46161',
    description:
      'Activities of agents involved in the wholesale of textiles, clothing, fur, footwear and leather goods'
  },
  {
    code: '46171',
    description: 'Activities of agents involved in the wholesale of food, beverages and tobacco'
  },
  {
    code: '46181',
    description: 'Activities of agents involved in the wholesale of other particular products'
  },
  {
    code: '46191',
    description: 'Activities of agents involved in non-specialised wholesale'
  },
  {
    code: '46211',
    description: 'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds'
  },
  {
    code: '46221',
    description: 'Wholesale of flowers and plants'
  },
  {
    code: '46231',
    description: 'Wholesale of live animals'
  },
  {
    code: '46241',
    description: 'Wholesale of hides, skins and leather'
  },
  {
    code: '46311',
    description: 'Wholesale of fruit and vegetables'
  },
  {
    code: '46321',
    description: 'Wholesale of meat and meat products'
  },
  {
    code: '46322',
    description: 'Wholesale of fish and fish products'
  },
  {
    code: '46331',
    description: 'Wholesale of dairy products, eggs and edible oils and fats'
  },
  {
    code: '46341',
    description: 'Wholesale of alcoholic beverages'
  },
  {
    code: '46349',
    description: 'Wholesale of other beverages'
  },
  {
    code: '46351',
    description: 'Wholesale of tobacco products'
  },
  {
    code: '46361',
    description: 'Wholesale of sugar, chocolate and sugar confectionery'
  },
  {
    code: '46362',
    description: 'Wholesale of bakery products'
  },
  {
    code: '46371',
    description: 'Wholesale of coffee, tea, cocoa and spices'
  },
  {
    code: '46381',
    description: 'Wholesale of other food'
  },
  {
    code: '46391',
    description: 'Non-specialised wholesale of food, beverages and tobacco'
  },
  {
    code: '46411',
    description: 'Wholesale of fabrics, household linen and haberdashery'
  },
  {
    code: '46421',
    description: 'Wholesale of clothing and clothing accessories'
  },
  {
    code: '46422',
    description: 'Wholesale of footwear'
  },
  {
    code: '46431',
    description: 'Wholesale of electrical household appliances'
  },
  {
    code: '46441',
    description: 'Wholesale of china and glassware products'
  },
  {
    code: '46442',
    description: 'Wholesale of cleaning materials'
  },
  {
    code: '46451',
    description: 'Wholesale of perfume and cosmetics'
  },
  {
    code: '46461',
    description: 'Wholesale of medicines and other pharmacy goods'
  },
  {
    code: '46462',
    description:
      'Wholesale of medical appliances and surgical and orthopaedic instruments and devices'
  },
  {
    code: '46471',
    description: 'Wholesale of household, office and shop furniture, carpets and lighting equipment'
  },
  {
    code: '46481',
    description: 'Wholesale of watches and jewellery'
  },
  {
    code: '46491',
    description: 'Wholesale of stationery, books, magazines and newspapers'
  },
  {
    code: '46492',
    description: 'Wholesale of sports good (excluding sports clothing and footwear)'
  },
  {
    code: '46493',
    description: 'Wholesale of games and toys'
  },
  {
    code: '46499',
    description: 'Wholesale of household goods, n.e.c.'
  },
  {
    code: '46501',
    description: 'Wholesale of computers, computer peripheral equipment and software'
  },
  {
    code: '46502',
    description: 'Wholesale of office machinery and equipment'
  },
  {
    code: '46503',
    description: 'Wholesale of electronic and telecommunication equipment and parts'
  },
  {
    code: '46611',
    description: 'Wholesale of agricultural machinery, equipment and supplies'
  },
  {
    code: '46621',
    description: 'Wholesale of machine tools'
  },
  {
    code: '46631',
    description: 'Wholesale of mining, construction and civil engineering machinery'
  },
  {
    code: '46649',
    description: 'Wholesale of other machinery and equipment'
  },
  {
    code: '46641',
    description:
      'Wholesale of machinery for the textile industry and of sewing and knitting machines'
  },
  {
    code: '46642',
    description:
      'Wholesale of lifting and transferring apparatus and machines and spares, including containers'
  },
  {
    code: '46643',
    description:
      'Wholesale of electrical material and their requisites and electrical machines, including cables'
  },
  {
    code: '46644',
    description: 'Wholesale of equipment used in food industry and commercial activities'
  },
  {
    code: '46711',
    description: 'Wholesale of motor vehicles'
  },
  {
    code: '46721',
    description: 'Wholesale of motor vehicle parts and accessories'
  },
  {
    code: '46731',
    description: 'Wholesale of motorcycles, motorcycle parts and accessories'
  },
  {
    code: '46811',
    description: 'Wholesale of solid fuels'
  },
  {
    code: '46812',
    description: 'Wholesale of automotive fuel'
  },
  {
    code: '46819',
    description: 'Wholesale of other liquid and gaseous fuels and similar'
  },
  {
    code: '46821',
    description: 'Wholesale of metals and metal ores'
  },
  {
    code: '46831',
    description: 'Wholesale of wood and products for the first-stage processing of wood'
  },
  {
    code: '46832',
    description: 'Wholesale of sanitary equipment and building materials n.e.c'
  },
  {
    code: '46841',
    description: 'Wholesale of plumbing and heating equipment and supplies'
  },
  {
    code: '46842',
    description: 'Wholesale of hand tools and general hardware'
  },
  {
    code: '46851',
    description: 'Wholesale of fertilisers and agrochemical products'
  },
  {
    code: '46859',
    description: 'Wholesale of other chemical products'
  },
  {
    code: '46861',
    description: 'Wholesale of other intermediate products'
  },
  {
    code: '46871',
    description: 'Wholesale of waste and scrap, buying up packaging and tare'
  },
  {
    code: '46891',
    description: 'Other specialised wholesale n.e.c.'
  },
  {
    code: '46901',
    description: 'Non-specialised wholesale trade'
  },
  {
    code: '47111',
    description: 'Non-specialised retail sale of predominately food, beverages or tobacco'
  },
  {
    code: '47121',
    description: 'Other non-specialised retail sale'
  },
  {
    code: '47211',
    description: 'Retail sale of fruit and vegetables'
  },
  {
    code: '47221',
    description: 'Retail sale of meat and meat products'
  },
  {
    code: '47231',
    description: 'Retail sale of fish, crustaceans and molluscs'
  },
  {
    code: '47241',
    description: 'Retail sale of bread and white bread, cake and confectionery'
  },
  {
    code: '47251',
    description: 'Retail sale of beverages'
  },
  {
    code: '47261',
    description: 'Retail sale of tobacco products'
  },
  {
    code: '47271',
    description: 'Retail sale of other food'
  },
  {
    code: '47301',
    description: 'Retail sale of automotive fuel'
  },
  {
    code: '47401',
    description: 'Retail sale of computers, peripheral units and software'
  },
  {
    code: '47402',
    description: 'Retail sale of communication equipment'
  },
  {
    code: '47403',
    description: 'Retail sale of audio and video equipment'
  },
  {
    code: '47511',
    description: 'Retail sale of textiles'
  },
  {
    code: '47521',
    description: 'Retail sale of hand tools and general hardware'
  },
  {
    code: '47522',
    description: 'Retail sale of sanitary and water supply equipment and supplies'
  },
  {
    code: '47529',
    description: 'Retail sale of other building material and goods'
  },
  {
    code: '47531',
    description: 'Retail sale of carpets, rugs, wall and floor coverings'
  },
  {
    code: '47541',
    description: 'Retail sale of electrical household appliances'
  },
  {
    code: '47551',
    description: 'Retail sale of furniture and articles for lighting'
  },
  {
    code: '47559',
    description: 'Retail sale of household articles and equipment n.e.c.'
  },
  {
    code: '47611',
    description: 'Retail sale of books'
  },
  {
    code: '47621',
    description: 'Retail sale of newspapers, and other periodical publications and stationery'
  },
  {
    code: '47631',
    description: 'Retail sale of sporting equipment'
  },
  {
    code: '47641',
    description: 'Retail sale of games and toys'
  },
  {
    code: '47691',
    description: 'Retail sale of cultural and recreational goods n.e.c.'
  },
  {
    code: '47711',
    description: 'Retail sale of clothing'
  },
  {
    code: '47721',
    description: 'Retail sale of footwear and leather goods'
  },
  {
    code: '47731',
    description: 'Retail sale of pharmaceutical products'
  },
  {
    code: '47741',
    description: 'Retail sale of medical and orthopaedic goods'
  },
  {
    code: '47742',
    description: 'Retail sale of glasses'
  },
  {
    code: '47751',
    description: 'Retail sale of cosmetic and toilet articles'
  },
  {
    code: '47761',
    description: 'Retail sale of flowers, plants, seeds, transplants and fertilizers'
  },
  {
    code: '47762',
    description: 'Retail sale of pet animals and birds, their food and goods'
  },
  {
    code: '47771',
    description: 'Retail sale of watches and jewellery'
  },
  {
    code: '47781',
    description: 'Retail sale of optical equipment'
  },
  {
    code: '47782',
    description: 'Retail sale of photographic and precision equipment'
  },
  {
    code: '47783',
    description: 'Retail sale of souvenirs, craftwork and religious articles'
  },
  {
    code: '47789',
    description: 'Retail sale of goods n.e.c.'
  },
  {
    code: '47791',
    description: 'Retail sale of antiques'
  },
  {
    code: '47792',
    description: 'Retail sale of second-hand books'
  },
  {
    code: '47799',
    description: 'Retail sale of other second-hand goods'
  },
  {
    code: '47811',
    description: 'Retail sale of motor vehicles'
  },
  {
    code: '47821',
    description: 'Retail sale of motor vehicle parts and accessories'
  },
  {
    code: '47831',
    description: 'Retail sale of motorcycles, motorcycle parts and accessories'
  },
  {
    code: '47911',
    description: 'Intermediation service activities for non-specialised retail sale'
  },
  {
    code: '47921',
    description: 'Intermediation service activities for specialised retail sale'
  },
  {
    code: '49111',
    description: 'Passenger heavy rail transport'
  },
  {
    code: '49121',
    description: 'Other passenger rail transport'
  },
  {
    code: '49201',
    description: 'Freight rail transport'
  },
  {
    code: '49311',
    description: 'Scheduled urban and suburban road transport of passengers'
  },
  {
    code: '49312',
    description: 'Scheduled long-distance and international road transport of passengers'
  },
  {
    code: '49319',
    description: 'Other scheduled local passenger transport (county and municipal lines)'
  },
  {
    code: '49321',
    description: 'Non-scheduled passenger transport by road'
  },
  {
    code: '49331',
    description: 'On-demand passenger transport service activities by vehicle with driver'
  },
  {
    code: '49341',
    description: 'Passenger transport by cableways and ski lifts'
  },
  {
    code: '49391',
    description: 'Other passenger land transport n.e.c.'
  },
  {
    code: '49411',
    description: 'Freight transport by road'
  },
  {
    code: '49421',
    description: 'Removal services'
  },
  {
    code: '49501',
    description: 'Transport via pipeline'
  },
  {
    code: '50101',
    description: 'Scheduled passengers transport at sea and in coastal waters'
  },
  {
    code: '50102',
    description: 'On-demand passenger transport at sea and in coastal waters'
  },
  {
    code: '50201',
    description: 'Sea and coastal freight water transport'
  },
  {
    code: '50301',
    description: 'Inland passenger water transport'
  },
  {
    code: '50401',
    description: 'Inland freight water transport'
  },
  {
    code: '51101',
    description: 'Passenger air transport'
  },
  {
    code: '51211',
    description: 'Freight air transport'
  },
  {
    code: '51221',
    description: 'Space transport'
  },
  {
    code: '52101',
    description: 'Operation of storage and warehouse facilities'
  },
  {
    code: '52102',
    description: 'Storage services of liquids and gases'
  },
  {
    code: '52211',
    description: 'Operation of car parks'
  },
  {
    code: '52219',
    description: 'Other service activities incidental to land transportation'
  },
  {
    code: '52221',
    description: 'Port and waterway operation services'
  },
  {
    code: '52222',
    description: 'Bunkering'
  },
  {
    code: '52229',
    description: 'Other service activities incidental to water transportation'
  },
  {
    code: '52231',
    description: 'Operation of airports and airway terminals'
  },
  {
    code: '52239',
    description: 'Other service activities incidental to air transportation'
  },
  {
    code: '52241',
    description: 'Cargo handling'
  },
  {
    code: '52251',
    description: 'Logistics service activities'
  },
  {
    code: '52261',
    description: 'Organising and forwarding freight consignments'
  },
  {
    code: '52262',
    description: 'Activities of customs agents'
  },
  {
    code: '52269',
    description: 'Other support activities for transportation n.e.c'
  },
  {
    code: '52311',
    description: 'Intermediation service activities for freight transportation'
  },
  {
    code: '52321',
    description: 'Intermediation service activities for passenger transportation'
  },
  {
    code: '53101',
    description: 'Postal activities under universal service obligation'
  },
  {
    code: '53201',
    description: 'Other postal and courier activities'
  },
  {
    code: '53301',
    description: 'Intermediation service activities for postal and courier activities'
  },
  {
    code: '55101',
    description: 'Hotels'
  },
  {
    code: '55102',
    description: 'Motels and similar accommodation'
  },
  {
    code: '55103',
    description: 'Guesthouses'
  },
  {
    code: '55201',
    description: 'Hostels'
  },
  {
    code: '55202',
    description: 'Holiday homes (chalets)'
  },
  {
    code: '55203',
    description: 'Holiday villages and camps'
  },
  {
    code: '55204',
    description: 'Visitor flats'
  },
  {
    code: '55205',
    description: 'Bed-and-breakfast'
  },
  {
    code: '55301',
    description: 'Camping grounds and recreational vehicle parks'
  },
  {
    code: '55401',
    description: 'Intermediation service activities for accommodation'
  },
  {
    code: '55901',
    description: 'Other accommodation'
  },
  {
    code: '56111',
    description: 'Activities of restaurants and cafes'
  },
  {
    code: '56112',
    description: 'Activities of fast food and other food service establishments'
  },
  {
    code: '56121',
    description: 'Mobile food service activities'
  },
  {
    code: '56211',
    description: 'Event catering activities'
  },
  {
    code: '56221',
    description: 'Contract catering service activities and other food service activities'
  },
  {
    code: '56301',
    description: 'Beverage serving activities'
  },
  {
    code: '56401',
    description: 'Intermediation service activities for food and beverage services activities'
  },
  {
    code: '58111',
    description: 'Publishing of books, brochures and similar publications'
  },
  {
    code: '58112',
    description: 'Publishing of textbooks, dictionaries and other encyclopaedias'
  },
  {
    code: '58121',
    description: 'Publishing of newspapers'
  },
  {
    code: '58131',
    description: 'Publishing of journals and periodicals'
  },
  {
    code: '58191',
    description: 'Other publishing activities, except software publishing'
  },
  {
    code: '58211',
    description: 'Publishing of video games'
  },
  {
    code: '58291',
    description: 'Other software publishing'
  },
  {
    code: '59111',
    description: 'Motion picture and video production'
  },
  {
    code: '59112',
    description: 'Production of television programmes'
  },
  {
    code: '59121',
    description: 'Motion picture, video and television programme post-production activities'
  },
  {
    code: '59131',
    description: 'Motion picture and video distribution activities'
  },
  {
    code: '59141',
    description: 'Motion picture projection activities'
  },
  {
    code: '59201',
    description: 'Audio recording service, including music recording'
  },
  {
    code: '59202',
    description: 'Music production, distribution and music publishing'
  },
  {
    code: '59209',
    description: 'Production and distribution of other sound recordings (except music)'
  },
  {
    code: '60101',
    description: 'Radio broadcasting and audio distribution activities'
  },
  {
    code: '60201',
    description: 'Television programming, broadcasting and video distribution activities'
  },
  {
    code: '60311',
    description: 'News agency activities'
  },
  {
    code: '60391',
    description: 'Other content distribution activities'
  },
  {
    code: '61101',
    description: 'Wired, wireless, and satellite telecommunication activities'
  },
  {
    code: '61201',
    description:
      'Telecommunication reselling activities and intermediation service activities for telecommunication'
  },
  {
    code: '61901',
    description: 'Other telecommunication activities'
  },
  {
    code: '62101',
    description: 'Computer programming activities'
  },
  {
    code: '62201',
    description: 'Computer consultancy activities'
  },
  {
    code: '62202',
    description: 'Computer facilities management activities'
  },
  {
    code: '62901',
    description: 'Other information technology and computer service activities'
  },
  {
    code: '63101',
    description: 'Computing infrastructure, data hosting and related activities'
  },
  {
    code: '63102',
    description: 'Data processing, data management, data mediation and related activities'
  },
  {
    code: '63911',
    description: 'Web search portal activities'
  },
  {
    code: '63921',
    description: 'Other information service activities'
  },
  {
    code: '64111',
    description: 'Central banking'
  },
  {
    code: '64191',
    description: 'Credit institutions'
  },
  {
    code: '64211',
    description: 'Activities of holding companies'
  },
  {
    code: '64221',
    description: 'Activities of financing conduits'
  },
  {
    code: '64311',
    description: 'Activities of money market and non-money market investments funds'
  },
  {
    code: '64321',
    description: 'Activities of trust, estate and agency accounts'
  },
  {
    code: '64911',
    description: 'Financial leasing'
  },
  {
    code: '64921',
    description: 'Pawn shops'
  },
  {
    code: '64929',
    description: 'Other credit granting, except pawn shops'
  },
  {
    code: '64991',
    description: 'Other financial service activities, except insurance and pension funding n.e.c.'
  },
  {
    code: '65111',
    description: 'Life insurance'
  },
  {
    code: '65121',
    description: 'Non-life insurance'
  },
  {
    code: '65201',
    description: 'Reinsurance'
  },
  {
    code: '65301',
    description: 'Pension funding'
  },
  {
    code: '66111',
    description: 'Administration of financial markets'
  },
  {
    code: '66121',
    description: 'Security and commodity contracts brokerage'
  },
  {
    code: '66122',
    description: 'Currency exchange'
  },
  {
    code: '66191',
    description: 'Financial consulting'
  },
  {
    code: '66199',
    description:
      'Other activities auxiliary to financial services, except insurance and pension funding n.e.c.'
  },
  {
    code: '66211',
    description: 'Risk and damage evaluation'
  },
  {
    code: '66221',
    description: 'Activities of insurance agents'
  },
  {
    code: '66222',
    description: 'Activities of insurance brokers'
  },
  {
    code: '66291',
    description: 'Activities auxiliary to insurance and pension funding n.e.c.'
  },
  {
    code: '66301',
    description: 'Fund management activities'
  },
  {
    code: '68111',
    description: 'Buying and selling of own real estate'
  },
  {
    code: '68121',
    description: 'Development of building projects'
  },
  {
    code: '68201',
    description: 'Rental and operating of own or leased real estate'
  },
  {
    code: '68311',
    description: 'Intermediation service activities for real estate activities'
  },
  {
    code: '68321',
    description:
      'Management of buildings and rental houses (apartment associations, housing associations, building associations etc.)'
  },
  {
    code: '68322',
    description: 'Management of gardening and cottage associations'
  },
  {
    code: '68329',
    description: 'Other real estate management or related activities'
  },
  {
    code: '69101',
    description: 'Activities of attorneys and law offices'
  },
  {
    code: '69102',
    description: 'Activities of legal counsels and legal offices'
  },
  {
    code: '69103',
    description: 'Activities of notaries'
  },
  {
    code: '69109',
    description: 'Other legal activities'
  },
  {
    code: '69201',
    description: 'Auditing'
  },
  {
    code: '69202',
    description: 'Bookkeeping, tax consulting'
  },
  {
    code: '70101',
    description: 'Activities of head offices'
  },
  {
    code: '70201',
    description: 'Business and other management consultancy activities'
  },
  {
    code: '71111',
    description: 'Architectural activities'
  },
  {
    code: '71121',
    description: 'Constructional engineering-technical designing and consulting'
  },
  {
    code: '71122',
    description: 'Construction geological and geodetic research'
  },
  {
    code: '71129',
    description: 'Other engineering-technical activities'
  },
  {
    code: '71201',
    description: 'Technical inspection of cars'
  },
  {
    code: '71209',
    description: 'Other testing and analysis'
  },
  {
    code: '72101',
    description: 'Research and experimental development on natural sciences and engineering'
  },
  {
    code: '72201',
    description: 'Research and experimental development on social sciences and humanities'
  },
  {
    code: '73111',
    description: 'Activities of advertising agencies'
  },
  {
    code: '73121',
    description: 'Media representation'
  },
  {
    code: '73201',
    description: 'Market research and public opinion polling'
  },
  {
    code: '73301',
    description: 'Public relations and communication activities'
  },
  {
    code: '74111',
    description: 'Industrial product and fashion design activities'
  },
  {
    code: '74121',
    description: 'Graphic design and visual communication activities'
  },
  {
    code: '74131',
    description: 'Interior design activities'
  },
  {
    code: '74141',
    description: 'Other specialised design activities'
  },
  {
    code: '74201',
    description: 'Photographic activities'
  },
  {
    code: '74301',
    description: 'Translation and interpretation activities'
  },
  {
    code: '74911',
    description: 'Patent brokering and marketing service activities'
  },
  {
    code: '74991',
    description: 'All other professional, scientific and technical activities n.e.c.'
  },
  {
    code: '75001',
    description: 'Veterinary activities'
  },
  {
    code: '77111',
    description:
      'Rental and leasing of cars and light motor vehicles (with a maximum permissible weight up to 3,5 tons)'
  },
  {
    code: '77121',
    description: 'Rental and leasing of trucks'
  },
  {
    code: '77211',
    description: 'Rental and leasing of recreational and sports goods'
  },
  {
    code: '77221',
    description: 'Rental and leasing of other personal and household goods'
  },
  {
    code: '77311',
    description: 'Rental and leasing of agricultural machinery and equipment'
  },
  {
    code: '77321',
    description: 'Rental and leasing of construction and civil engineering machinery and equipment'
  },
  {
    code: '77331',
    description: 'Rental and leasing of office machinery, equipment and computers'
  },
  {
    code: '77341',
    description: 'Rental and leasing of water transport equipment'
  },
  {
    code: '77351',
    description: 'Rental and leasing of air transport equipment'
  },
  {
    code: '77399',
    description: 'Rental and leasing of other machinery, equipment and tangible goods n.e.c.'
  },
  {
    code: '77391',
    description: 'Rental and leasing of rail vehicles'
  },
  {
    code: '77401',
    description: 'Leasing of intellectual property and similar products, except copyrighted works'
  },
  {
    code: '77511',
    description:
      'Intermediation service activities for rental and leasing of cars, motorhomes and trailers'
  },
  {
    code: '77521',
    description:
      'Intermediation service activities for rental and leasing of other tangible goods and non-financial intangible assets'
  },
  {
    code: '78101',
    description: 'Activities of employment placement agencies'
  },
  {
    code: '78201',
    description: 'Temporary employment agency activities'
  },
  {
    code: '78209',
    description: 'Other human resources provision'
  },
  {
    code: '79111',
    description: 'Travel agency activities'
  },
  {
    code: '79121',
    description: 'Tour operator activities'
  },
  {
    code: '79901',
    description: 'Other reservation service and related activities'
  },
  {
    code: '80011',
    description: 'Investigation and private security activities'
  },
  {
    code: '80091',
    description: 'Security activities n.e.c.'
  },
  {
    code: '81101',
    description: 'Combined facilities support activities'
  },
  {
    code: '81211',
    description: 'General cleaning of buildings'
  },
  {
    code: '81221',
    description: 'Other building and industrial cleaning activities'
  },
  {
    code: '81231',
    description: 'Other cleaning activities'
  },
  {
    code: '81301',
    description: 'Landscape service activities'
  },
  {
    code: '82101',
    description: 'Office administrative and support activities'
  },
  {
    code: '82201',
    description: 'Activities of call centres'
  },
  {
    code: '82301',
    description: 'Organisation of conventions and trade shows'
  },
  {
    code: '82401',
    description: 'Intermediation service activities for business support service activities n.e.c.'
  },
  {
    code: '82911',
    description: 'Activities of collection agencies and credit bureaus'
  },
  {
    code: '82921',
    description: 'Packaging activities'
  },
  {
    code: '82991',
    description: 'Other business support service activities n.e.c.'
  },
  {
    code: '84111',
    description: 'Activities of executive and legislative bodies'
  },
  {
    code: '84112',
    description: 'Activities related to monetary and fiscal policy'
  },
  {
    code: '84113',
    description: 'Activities related to general economic and social planning and statistics'
  },
  {
    code: '84114',
    description: 'Activities of rural municipality and city governments'
  },
  {
    code: '84119',
    description: 'General public administration activities n.e.c'
  },
  {
    code: '84121',
    description: 'Administration of education'
  },
  {
    code: '84122',
    description: 'Administration of health care and social services'
  },
  {
    code: '84123',
    description: 'Administration of housing and utilities'
  },
  {
    code: '84124',
    description: 'Administration of recreational activities, culture and religion'
  },
  {
    code: '84125',
    description: 'Administration of environmental protection'
  },
  {
    code: '84131',
    description: 'Administration of agriculture, forestry, land use, fishery and hunting'
  },
  {
    code: '84132',
    description: 'Administration of transport and communication'
  },
  {
    code: '84139',
    description: 'Other economy supporting activities'
  },
  {
    code: '84211',
    description: 'Foreign affairs'
  },
  {
    code: '84221',
    description: 'Defence activities'
  },
  {
    code: '84231',
    description: 'Administration and activities of courts'
  },
  {
    code: '84232',
    description: 'Administration of prisons and correctional facilities'
  },
  {
    code: '84239',
    description:
      'Other activities related to legal protection and courts, activities of the prosecuting authority'
  },
  {
    code: '84241',
    description: 'Activities and management of police forces'
  },
  {
    code: '84242',
    description: 'Border and coast guard services'
  },
  {
    code: '84249',
    description: 'Other activities related to public order and security'
  },
  {
    code: '84251',
    description: 'Fire and rescue services, including lifeguard services'
  },
  {
    code: '84301',
    description: 'Compulsory social security activities'
  },
  {
    code: '85101',
    description: 'Creches'
  },
  {
    code: '85102',
    description: 'Nurseries'
  },
  {
    code: '85201',
    description: 'Nursery-elementary schools'
  },
  {
    code: '85202',
    description: 'Elementary schools'
  },
  {
    code: '85311',
    description: 'Nursery-basic schools'
  },
  {
    code: '85312',
    description: 'Basic schools'
  },
  {
    code: '85313',
    description: 'Basic and upper secondary schools'
  },
  {
    code: '85314',
    description: 'Upper secondary schools'
  },
  {
    code: '85321',
    description: 'Vocational educational institutions'
  },
  {
    code: '85331',
    description: 'Post-secondary non-tertiary education'
  },
  {
    code: '85401',
    description: 'Professional higher education institutions'
  },
  {
    code: '85402',
    description: 'Academic higher education institutions'
  },
  {
    code: '85511',
    description: 'Sports schools'
  },
  {
    code: '85519',
    description: 'Other sports and recreational education'
  },
  {
    code: '85521',
    description: 'Music and art education'
  },
  {
    code: '85522',
    description: 'Activities of dance schools'
  },
  {
    code: '85529',
    description: 'Other art and cultural education'
  },
  {
    code: '85531',
    description: 'Driving school activities'
  },
  {
    code: '85599',
    description: 'Other education n.e.c.'
  },
  {
    code: '85591',
    description: 'Language training'
  },
  {
    code: '85592',
    description: 'Computer training'
  },
  {
    code: '85593',
    description: 'Natural sciences, mathematics, and technology education'
  },
  {
    code: '85611',
    description: 'Intermediation service activities for courses and tutors'
  },
  {
    code: '85699',
    description: 'Educational support activities n.e.c.'
  },
  {
    code: '85691',
    description: 'Education support services'
  },
  {
    code: '86101',
    description: 'Hospital activities'
  },
  {
    code: '86211',
    description: 'General medical practice activities'
  },
  {
    code: '86221',
    description: 'Medical specialists activities'
  },
  {
    code: '86231',
    description: 'Dental practice care activities'
  },
  {
    code: '86911',
    description: 'Diagnostic imaging services and medical laboratory activities'
  },
  {
    code: '86921',
    description: 'Patient transportation by ambulance'
  },
  {
    code: '86931',
    description: 'Activities of psychologists and psychotherapists, except medical doctors'
  },
  {
    code: '86941',
    description: 'Provision of nursing care'
  },
  {
    code: '86942',
    description: 'Activities of midwives'
  },
  {
    code: '86951',
    description: 'Physiotherapy activities'
  },
  {
    code: '86961',
    description: 'Traditional, complementary and alternative medicine activities'
  },
  {
    code: '86971',
    description:
      'Intermediation service activities for medical, dental and other human health services'
  },
  {
    code: '86992',
    description: 'Other human health activities n.e.c.'
  },
  {
    code: '86991',
    description: 'Blood-, sperm and similar banks activities'
  },
  {
    code: '87101',
    description: 'Residential nursing care activities'
  },
  {
    code: '87201',
    description:
      'Residential care activities for persons living with intellectual disabilities or having a diagnosis of a mental illness'
  },
  {
    code: '87202',
    description: 'Residential care activities for persons with substance abuse issues'
  },
  {
    code: '87301',
    description:
      'Residential care activities for older persons or persons with physical disabilities'
  },
  {
    code: '87911',
    description: 'Intermediation service activities for residential care activities'
  },
  {
    code: '87999',
    description: 'Other residential care activities n.e.c.'
  },
  {
    code: '87991',
    description: 'Activity of institutions providing alternative care service'
  },
  {
    code: '88101',
    description:
      'Social work activities without accommodation for older persons or persons with disabilities'
  },
  {
    code: '88911',
    description: 'Child day-care activities'
  },
  {
    code: '88991',
    description: 'Other social work activities without accommodation n.e.c.'
  },
  {
    code: '90111',
    description: 'Literary creation activities'
  },
  {
    code: '90112',
    description: 'Musical composition activities'
  },
  {
    code: '90121',
    description: 'Visual arts creation activities'
  },
  {
    code: '90131',
    description: 'Other arts creation activities'
  },
  {
    code: '90201',
    description: 'Stage performance of actors and dancers'
  },
  {
    code: '90202',
    description: 'Stage performance of musicians'
  },
  {
    code: '90209',
    description: 'Activities of other performers'
  },
  {
    code: '90311',
    description: 'Operation of arts facilities and sites'
  },
  {
    code: '90391',
    description: 'Technical support activities for art and performing arts'
  },
  {
    code: '90399',
    description: 'Other support activities to arts and performing arts, except technical'
  },
  {
    code: '91111',
    description: 'Library activities'
  },
  {
    code: '91121',
    description: 'Archive activities'
  },
  {
    code: '91211',
    description: 'Museum and collection activities'
  },
  {
    code: '91221',
    description: 'Historical site and monument activities'
  },
  {
    code: '91301',
    description: 'Conservation, restoration and other support activities for cultural heritage'
  },
  {
    code: '91411',
    description: 'Botanical and zoological garden activities'
  },
  {
    code: '91421',
    description: 'Nature reserve activities'
  },
  {
    code: '92001',
    description: 'Gambling and betting activities'
  },
  {
    code: '93111',
    description: 'Operation of sports facilities'
  },
  {
    code: '93121',
    description: 'Activities of sports clubs'
  },
  {
    code: '93122',
    description: 'Activities of sports clubs for the disabled'
  },
  {
    code: '93131',
    description: 'Activities of fitness centres'
  },
  {
    code: '93191',
    description: 'Activities of sports leagues, organisations and associations'
  },
  {
    code: '93192',
    description: 'Activities related to sport and recreational fishing and hunting'
  },
  {
    code: '93199',
    description: 'Other sports activities n.e.c.'
  },
  {
    code: '93211',
    description: 'Activities of amusement parks and theme parks'
  },
  {
    code: '93291',
    description: 'Amusement and recreation activities n.e.c.'
  },
  {
    code: '94111',
    description:
      'Agricultural associations and unions, gardening and apicultural associations, forest associations and unions'
  },
  {
    code: '94119',
    description: 'Activities of other business and employers organisations'
  },
  {
    code: '94121',
    description: 'Activities of artistic associations'
  },
  {
    code: '94129',
    description: 'Activities of other professional membership organisations'
  },
  {
    code: '94201',
    description: 'Activities of trade unions'
  },
  {
    code: '94911',
    description: 'Activities of churches, congregations and monasteries'
  },
  {
    code: '94919',
    description: 'Activities of other religious organisations'
  },
  {
    code: '94921',
    description: 'Activities of parties'
  },
  {
    code: '94929',
    description: 'Activities of other political organisations'
  },
  {
    code: '94991',
    description:
      'Protection and advocacy of civil rights; protection of the interests of certain population groups'
  },
  {
    code: '94992',
    description: 'Associations for the purpose of regional/local life development and support'
  },
  {
    code: '94993',
    description:
      'Youth and children associations and associations that promote the welfare of youth and children'
  },
  {
    code: '94994',
    description: 'Associations and cultural societies of national minorities'
  },
  {
    code: '94995',
    description:
      'Associations related to recreational activities, entertainment, cultural activities or hobbies'
  },
  {
    code: '94996',
    description: 'Environment and nature protection associations'
  },
  {
    code: '94997',
    description:
      'Associations and their unions of individuals with health impairments, disabilities, or special needs'
  },
  {
    code: '94999',
    description: 'Activities of other organisations n.e.c.'
  },
  {
    code: '95101',
    description: 'Repair and maintenance of computers and peripheral equipment'
  },
  {
    code: '95102',
    description: 'Repair and maintenance of communication equipment'
  },
  {
    code: '95211',
    description: 'Repair and maintenance of consumer electronics'
  },
  {
    code: '95221',
    description: 'Repair and maintenance of household appliances and home and garden equipment'
  },
  {
    code: '95231',
    description: 'Repair and maintenance of footwear and leather goods'
  },
  {
    code: '95241',
    description: 'Repair and maintenance of furniture and home furnishings'
  },
  {
    code: '95251',
    description: 'Repair and maintenance of watches, clocks and jewellery'
  },
  {
    code: '95291',
    description: 'Repair and maintenance of personal and household goods n.e.c.'
  },
  {
    code: '95311',
    description: 'Repair and maintenance of motor vehicles'
  },
  {
    code: '95321',
    description: 'Repair and maintenance of motorcycles'
  },
  {
    code: '95401',
    description:
      'Intermediation service activities for repair and maintenance of computers, personal and household goods, and motor vehicles and motorcycles'
  },
  {
    code: '96101',
    description: 'Washing and cleaning of textile and fur products'
  },
  {
    code: '96211',
    description: 'Hairdressing and barber activities'
  },
  {
    code: '96221',
    description: 'Beauty treatment services by a cosmetologist'
  },
  {
    code: '96222',
    description: 'Nail technician services'
  },
  {
    code: '96223',
    description: 'Eyelash and eyebrow technician services'
  },
  {
    code: '96224',
    description: 'Permanent makeup services'
  },
  {
    code: '96229',
    description: 'Other beauty care and treatment activities'
  },
  {
    code: '96231',
    description: 'Day spa, massage salons and sauna activities'
  },
  {
    code: '96232',
    description: 'Activities of sunbeds'
  },
  {
    code: '96301',
    description: 'Activities of funeral homes and crematoriums'
  },
  {
    code: '96302',
    description: 'Activities cemeteries'
  },
  {
    code: '96401',
    description: 'Intermediation service activities for personal services'
  },
  {
    code: '96911',
    description: 'Provision of domestic personal service activities'
  },
  {
    code: '96999',
    description: 'Other personal service activities n.e.c.'
  },
  {
    code: '96991',
    description: 'Pet care services'
  },
  {
    code: '97001',
    description: 'Activities of households as employers of domestic personnel'
  },
  {
    code: '98101',
    description: 'Undifferentiated goods-producing activities of private households for own use'
  },
  {
    code: '98201',
    description: 'Undifferentiated service-producing activities of private households for own use'
  },
  {
    code: '99001',
    description: 'Activities of extraterritorial organisations and bodies'
  }
];

export default emtak;
