import React, { useEffect, useState } from 'react';
import { withErrorBoundary } from '@sentry/react';
import { Box, CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { FormattedMessage, useIntl } from 'react-intl';
import Confetti from 'react-confetti';

// eslint-disable-next-line import/no-extraneous-dependencies
import { IntlShape } from '@formatjs/intl/src/types';
import {
  RegistrationStepper,
  RegistrationStepperMobile
} from '../../../common/components/RegistrationStepper';
import RegistrationButton from '../../../common/components/RegistrationButton';
import CompanyInfoForm from './CompanyInfoForm';
import PaymentForm from './PaymentForm';
import CompanyNameForm from './CompanyNameForm';
import validate from './utils/fieldValidation';
import {
  dateToDDMMYYYYString,
  isValidE164PhoneNumber,
  isValidEmail,
  isValidNumber,
  isValidString,
  uniqueIdForPerson
} from '../../../common/utils/validations';
import emtak from '../../../translations/emtak/emtak_summary_en';
import './index.css';
import {
  CompanyState,
  Member,
  MergedState,
  NewMemberState,
  PaymentInfoState,
  Localization,
  TokenInfo,
  EmtakType,
  CompanyTerms
} from '../types';
import { emptyMember } from './utils/constants';
import useCrispChat from '../../../common/utils/chispChatHook';
import CompanyCheckRegistry from './CompanyCheckRegistry';
import CompanyMissingInfo from './CompanyMissingInfo';
import { callGtagEvent, setGtagVariables } from '../../../common/utils/handleGtag';
import CompanyType from './CompanyType';
import {
  GOOGLE_CONVERSION_ID,
  GOOGLE_CONVERSION_TAG_STEP_2,
  GOOGLE_CONVERSION_TAG_STEP_3,
  LINKEDIN_CONVERSION_TAG_STEP_2,
  LINKEDIN_CONVERSION_TAG_STEP_3
} from '../../../common/utils/constants';
// eslint-disable-next-line import/no-named-as-default
import callLinkedinTagEvent from '../../../common/utils/handleLinkedinTag';
import CompanyTermsConfirmation from './CompanyTermsConfirmation';

function NextStepButtonTxt(states: MergedState, intl: IntlShape) {
  const { isLoading, step, isSwitch, companyConfirmed } = states;
  const { formatMessage } = intl;
  if (isLoading) {
    return <CircularProgress size={18} />;
  }

  if (step === 1 && isSwitch) {
    if (!companyConfirmed) {
      return formatMessage({ id: 'next', defaultMessage: 'Next' });
    }
  }

  if (step === 4) {
    return formatMessage({ id: 'i_agree', defaultMessage: 'I agree!' });
  }

  return formatMessage({ id: 'next_step', defaultMessage: 'Next Step' });
}

function Index({ setLocale, locale }: Localization) {
  // Company and members data
  const [company, setCompany] = useState<CompanyState>({
    company_name: '',
    activity_code: '',
    share_capital: '1',
    phone: '',
    activity_description: '',
    email: '',
    unassigned_capital: 1,
    company_type: ''
  });
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfoState>({
    base: 0,
    discount: 0,
    members: 0,
    total: 0,
    vat: 0,
    vat_percentage: 0,
    accountancy: false,
    privacy_policy: false
  });
  const [newMember, setNewMember] = useState<NewMemberState>(emptyMember);
  const [members, setMembers] = useState<NewMemberState[]>([]);
  const [tokenInfo, setTokenInfo] = useState<null | TokenInfo>(null);

  // Errors and validation data
  const [companyErrors, setCompanyErrors] = useState<any>({});
  const [newMemberErrors, setNewMemberErrors] = useState<any>({});
  const [membersError, setMembersError] = useState<any>('');
  const [companyNameValidity, setCompanyNameValidity] = useState('');

  // UI controls
  const [updatingMemberIndex, setUpdatingMemberIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitch, setIsSwitch] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [step, setStep] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [companyConfirmed, setCompanyConfirmed] = useState(false);

  const [unsupportedCountriesList, setUnsupportedCountriesList] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<EmtakType>({
    code: 0,
    description: '',
    includes: '',
    allowed: false,
    needs_license: false,
    warning: ''
  });

  // Company terms confirmations
  const [companyTermsCheckboxes, setCheckboxes] = useState<CompanyTerms>({
    payForDrinks: false,
    issueNewCrypto: false,
    conductCashTransactions: false,
    shipPhysicalGoods: false,
    investmentActivities: false,
    ownRealEstate: false,
    makeDonations: false
  });

  // States aggregated
  const currentStates: MergedState = {
    company,
    newMember,
    members,
    modalAction,
    updatingMemberIndex,
    isLoading,
    step,
    companyConfirmed,
    isSwitch
  };

  useCrispChat();
  const intl = useIntl();
  const navigate = useNavigate();
  const { token, email } = useParams();

  useEffect(() => {
    const unsupportedCountries = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/misc/unsupported_countries`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (!data.error) {
        setUnsupportedCountriesList(data.unsupported_countries);
      } else {
        setUnsupportedCountriesList([]);
      }
    };
    unsupportedCountries();
  }, []);

  // Decode the user JWT token and set important states
  useEffect(() => {
    // Show the members empty error message at the first render
    const error = validate(
      'members',
      members,
      currentStates,
      intl as IntlShape,
      isSwitch,
      unsupportedCountriesList
    );
    if ((!error && isValidString(membersError)) || !error) {
      setMembersError('');
    } else {
      setMembersError(error);
    }

    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/token/verify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      });
      const data = await response.json();
      if (!data.error) {
        setTokenInfo((t) => {
          return {
            ...t,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            ...(data.referral_code ? { referral_code: data.referral_code } : null),
            ...(data.affiliate_code ? { affiliate_code: data.affiliate_code } : null),
            ...(data.promo_code ? { promo_code: data.promo_code } : null),
            ...(data.type === 'switching'
              ? {
                  switch_data: {
                    reason: data.switch_reason,
                    previous_provider: data.switch_provider
                  }
                }
              : null),
            ...(data.type === 'switching' ? { is_switch: true } : null),
            ...(data.company_type ? { company_type: data.company_type } : null)
          };
        });
        setCompany({
          ...company,
          ...(data.referral_code ? { referral_code: data.referral_code } : null),
          ...(data.affiliate_code ? { affiliate_code: data.affiliate_code } : null),
          ...(data.promo_code ? { promo_code: data.promo_code } : null),
          ...(data.type === 'switching'
            ? {
                switch_data: {
                  reason: data.switch_reason,
                  previous_provider: data.switch_provider
                }
              }
            : null),
          ...(data.type === 'switching' ? { is_switch: true } : null),
          ...(data.type === 'switching' ? { company_confirmed: false } : null),
          ...(data.type === 'switching' ? null : { email: data.email }),
          ...(data.company_type ? { company_type: data.company_type } : null)
        });
        setPaymentInfo({
          ...paymentInfo,
          accountancy: data.company_type === 'virtual'
        });
        setLocale(data.locale || 'en');
        if (data.type === 'switching') {
          setIsSwitch(true);
        } else {
          setIsSwitch(false);
        }
        if (data.company_type) {
          setStep(1);
        }
      } else {
        const decoded = Object.prototype.hasOwnProperty.call(data, 'decoded') ? data.decoded : null;
        let navigateParams = {};
        if (decoded) {
          navigateParams = {
            ...(decoded.referral_code ? { referral_code: decoded.referral_code } : null),
            ...(decoded.affiliate_code ? { affiliate_code: decoded.affiliate_code } : null),
            ...(decoded.promo_code ? { promo_code: decoded.promo_code } : null),
            ...(decoded.locale ? { locale: decoded.locale } : null)
          };
        }
        toast.error(data.message);
        navigate({
          pathname: '/',
          search: createSearchParams({
            ...navigateParams
          }).toString()
        });
      }
    };

    fetchData();
  }, [token, email, locale]);

  // Listen to updates in the members state, and check errors
  useEffect(() => {
    const error = validate(
      'members',
      members,
      currentStates,
      intl as IntlShape,
      isSwitch,
      unsupportedCountriesList
    );
    if ((!error && isValidString(membersError)) || !error) {
      setMembersError('');
    } else {
      setMembersError(error);
    }

    // @ts-ignore
    setCompany({
      ...company,
      ...(members.length
        ? {
            unassigned_capital: isValidNumber(Number(company.share_capital))
              ? Number(company.share_capital) -
                members.reduce((a, i) => a + Number(i.shareholder_contribution), 0)
              : ''
          }
        : {
            unassigned_capital: isValidNumber(Number(company.share_capital))
              ? company.share_capital
              : ''
          })
    });
  }, [members]);

  // Ensures only one representative when adding new members
  useEffect(() => {
    if (modalAction === 'Add') {
      let reps = 0;
      let lastAdded = 0;
      members.forEach((m, index) => {
        if (m.is_representative) {
          reps += 1;
          lastAdded = index;
        }
      });

      if (reps > 1 && lastAdded > 0) {
        setMembers((prevState) => {
          return prevState.map((m, i) => {
            if (i !== lastAdded) {
              return { ...m, is_representative: false };
            }
            return m;
          });
        });
      }
    }
  }, [members]);

  const handleCompanyInputChange = (e: React.SyntheticEvent) => {
    const key = (e.target as HTMLElement).id
      ? (e.target as HTMLElement).id
      : (e.target as HTMLInputElement).name;
    const { value } = e.target as HTMLInputElement;

    // special cases
    let updateObj = {};

    if (key === 'share_capital') {
      if (newMemberErrors?.shareholder) {
        setNewMemberErrors((errors: any) => {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          delete errors.shareholder;
          return errors;
        });
      }
      if (members.length === 1) {
        setMembers((prevState) => {
          return prevState.map((m) => {
            // eslint-disable-next-line no-param-reassign
            m.shareholder_contribution = value;
            return m;
          });
        });
      }
      updateObj = { [key]: value, unassigned_capital: value };
    } else {
      updateObj = { [key]: value };
    }

    // update state
    // @ts-ignore
    setCompany({ ...company, ...updateObj });
    // handle errors
    const error = validate(
      key,
      value,
      currentStates,
      intl as IntlShape,
      isSwitch,
      unsupportedCountriesList
    );
    if (!error && Object.prototype.hasOwnProperty.call(companyErrors, key)) {
      setCompanyErrors((errors: any) => {
        if (errors) {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          delete errors[key];
        }
        return errors;
      });
    } else if (error) {
      // @ts-ignore
      setCompanyErrors({ ...companyErrors, [key]: error });
    }
  };
  const handleAutocompleteChange = (event: React.SyntheticEvent, value: EmtakType | null) => {
    if (value === null) {
      setSelectedOption({
        code: 0,
        description: '',
        includes: '',
        allowed: false,
        needs_license: false,
        warning: ''
      });
      setCompany({ ...company, activity_code: '' });
    } else {
      setSelectedOption(value);
      setCompany({ ...company, activity_code: value?.description });
    }
  };

  const handlePaymentInfoCheckboxChange = (e: React.SyntheticEvent) => {
    const { id } = e.target as HTMLElement;
    setPaymentInfo({
      ...paymentInfo,
      // @ts-ignore
      [id]: !paymentInfo[id]
    });
  };

  const handleNewMemberInputChange = (e: React.SyntheticEvent) => {
    // @ts-ignore
    const key = (e.target as HTMLElement).id
      ? (e.target as HTMLElement).id
      : (e.target as HTMLInputElement).name;
    // @ts-ignore
    const { value } = e.target as HTMLElement;

    // update states
    setNewMember({ ...newMember, [key]: value });

    // handle errors
    const error = validate(
      key,
      value,
      currentStates,
      intl as IntlShape,
      isSwitch,
      unsupportedCountriesList
    );
    if (!error && Object.prototype.hasOwnProperty.call(newMemberErrors, key)) {
      setNewMemberErrors((errors: any) => {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        delete errors[key];
        return errors;
      });
    } else if (error) {
      // @ts-ignore
      setNewMemberErrors({ ...newMemberErrors, [key]: error });
    }
  };

  const handleMemberInputBirthDate = (date: string) => {
    setNewMember({
      ...newMember,
      birthdate: date
    });
  };

  const handleAlreadyEResidentCheckboxChange = (e: React.SyntheticEvent) => {
    const { id } = e.target as HTMLElement;
    setNewMemberErrors((errors: any) => {
      // eslint-disable-next-line no-param-reassign
      delete errors.eresident_id;
      // eslint-disable-next-line no-param-reassign
      delete errors.birthdate;
      return errors;
    });
    // @ts-ignore
    setNewMember({ ...newMember, [id]: !newMember[id], eresident_id: '', birthdate: null });
  };

  const handleNewMemberCheckboxChange = (e: React.SyntheticEvent) => {
    const { id } = e.target as HTMLElement;
    /* eslint-disable prettier/prettier */
    // @ts-ignore
    const error = validate(id, !newMember[id], currentStates, intl, isSwitch, unsupportedCountriesList);

    if (!error && Object.prototype.hasOwnProperty.call(newMemberErrors, id)) {
      setNewMemberErrors((errors: any) => {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        delete errors[id];
        return errors;
      });
    } else if (error) {
      // @ts-ignore
      setNewMemberErrors({ ...newMemberErrors, [id]: error });
    }
    // @ts-ignore
    setNewMember({ ...newMember, [id]: !newMember[id] });
  };

  const handleCompanyTermsCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setCheckboxes((prevState) => ({
      ...prevState,
      [id]: checked
    }));
  };

  const handleIsRepresentativeCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setNewMember({ ...newMember, is_representative: true });
      setMembers((prevState) => {
        return prevState.map((m, i) => {
          if (i === updatingMemberIndex) {
            return { ...m, is_representative: true };
          }
          return { ...m, is_representative: false };
        });
      });
      return;
    }
    setNewMember({ ...newMember, is_representative: false });
  };

  const handleCompanyCheckboxChange = (e: React.SyntheticEvent) => {
    const { id } = e.target as HTMLElement;
    // @ts-ignore
    setCompany({ ...company, [id]: !company[id] });
  };

  const handleClearNewMember = () => {
    setNewMember(emptyMember);
  };

  const handleUpdateShareCapitalContribution = (e: Event) => {
    const index = parseInt((e.target as HTMLInputElement).name, 10);
    const value = parseFloat((e.target as HTMLInputElement).value || '0');

    const currentUnassignedCapital = isValidNumber(Number(company.share_capital))
      ? Number(company.share_capital) -
        members.reduce((a, i) => a + Number(i.shareholder_contribution), 0)
      : 0;
    const shareholders = members.filter((m) => m.shareholder);

    setMembers(
      members.map((item, i) => {
        const member: NewMemberState = item;
        if (shareholders.length === 2) {
          if (index === i && member.shareholder) {
            member.shareholder_contribution = String(value);
          } else if (member.shareholder) {
            member.shareholder_contribution = String(parseFloat(company.share_capital) - value);
          }
        } else if (currentUnassignedCapital <= 0) {
          if (value < parseFloat(member.shareholder_contribution || '0') && i === index) {
            member.shareholder_contribution = String(value);
          }
        } else if (i === index) {
          const assignedCapital =
            parseFloat(company.share_capital) -
            currentUnassignedCapital -
            parseFloat(member.shareholder_contribution || '0');
          const valueOutOfTheUnassignedCapital =
            parseFloat(company.share_capital) - (value + assignedCapital);
          const safeValue =
            valueOutOfTheUnassignedCapital <= 0 ? value + valueOutOfTheUnassignedCapital : value;
          member.shareholder_contribution = String(safeValue);
        }
        return member;
      })
    );
  };

  const handleAddUpdateMember = () => {
    if (modalAction === 'Add') {
      setMembers([...members, newMember]);
      return;
    }

    if (modalAction === 'Update') {
      setMembers(
        members.map((item, i) => {
          if (updatingMemberIndex === i) {
            return newMember;
          }
          return item;
        })
      );
    }

    handleClearNewMember();
  };

  const handleRemoveMemberByIndex = (index: number) => {
    // Preserve the member information from token
    if (index === 0) {
      setNewMember({
        ...emptyMember,
        first_name: tokenInfo ? members[index].first_name : '',
        last_name: tokenInfo ? members[index].last_name : '',
        email: tokenInfo ? members[index].email : '',
        is_representative: true,
        board_member: true,
        shareholder: true,
        shareholder_contribution: company.share_capital
      });
    }

    let updatedMembers = [...members];
    if (updatedMembers.length === 2) {
      updatedMembers = updatedMembers.map((m, i) => {
        if (index !== i) {
          return {
            ...m,
            is_representative: true,
            board_member: true,
            shareholder: true,
            shareholder_contribution: company.share_capital
          };
        }
        return m;
      });
    }
    setMembers(updatedMembers.filter((m: NewMemberState, i) => index !== i));
  };

  const handleShowMemberModal = (e: React.SyntheticEvent, index: number) => {
    const action = (e.target as HTMLAnchorElement).id;
    setModalAction(action);

    if (action === 'Add') {
      if (!members.length) {
        setNewMember({
          ...emptyMember,
          first_name: tokenInfo ? tokenInfo.first_name : '',
          last_name: tokenInfo ? tokenInfo.last_name : '',
          email: tokenInfo ? tokenInfo.email : '',
          is_representative: true,
          board_member: true,
          shareholder: true,
          shareholder_contribution: company.share_capital,
          phone: company.phone
        });
      } else {
        setNewMember(emptyMember);
      }
    } else if (action === 'Update') {
      const member = members[index];
      setNewMember(member);
      setUpdatingMemberIndex(index);
    }
    setShowMemberModal(true);
  };

  const handleCloseMemberModal = () => {
    setShowMemberModal(false);
  };

  const handleValidateMemberFields = () => {
    const isInvalidBasicInfo: boolean =
      newMember.first_name === '' ||
      newMember.last_name === '' ||
      newMember.email === '' ||
      newMember.address_street === '' ||
      newMember.address_city === '' ||
      newMember.address_zip === '' ||
      newMember.address_country === '' ||
      newMember.phone === '' ||
      (newMember.eresident_id === '' && !newMember.birthdate) ||
      newMember.pep;
    const notShareholderOrBoardMember: boolean = !newMember.board_member && !newMember.shareholder;

    return isInvalidBasicInfo || notShareholderOrBoardMember;
  };

  const handleAllFieldsFullFilled = (): boolean => {
    if (step === 2) {
      if (isSwitch) {
        if (companyConfirmed) {
          const allMembersCorrect = members.map((m: NewMemberState) => {
            return (
              isValidEmail(m.email) &&
              isValidE164PhoneNumber(m.phone) &&
              isValidString(m.address_street) &&
              isValidString(m.address_city) &&
              isValidString(m.address_zip) &&
              isValidString(m.address_country)
            );
          });
          return (
            allMembersCorrect.includes(false) ||
            !isValidEmail(company.email) ||
            !isValidE164PhoneNumber(company.phone) ||
            isValidString(companyErrors) ||
            isValidString(membersError)
          );
        }
        return !company.company_confirmed;
      }
      return (
        company.activity_code === '' ||
        company.share_capital === '' ||
        company.unassigned_capital !== 0 ||
        company.activity_description === '' ||
        company.email === '' ||
        members.length <= 0 ||
        isValidString(membersError) ||
        Object.keys(companyErrors).length > 0
      );
    }

    if (step === 3) {
      return !Object.values(companyTermsCheckboxes).every(Boolean)
    }

    if (step === 4) {
      return !paymentInfo.privacy_policy || !paymentInfo.accountancy;
    }
    return false;
  };

  const handlePreviousStep = () => {
    setStep((prevState) => {
      if (prevState === 0) {
        return prevState;
      }
      if (prevState === 1 && isSwitch) {
        setCompany({
          ...company,
          registry_number: '',
          company_confirmed: false
        });
        setCompanyConfirmed(false);
      }
      return prevState - 1;
    });
  };

  const handleNextStep = () => {
    setStep((prevState) => {
      if (prevState === 2) {
        if (isSwitch && !companyConfirmed && company.company_confirmed) {
          setIsLoading(false);
          setCompanyConfirmed(true);
          try {
            setGtagVariables({
              email: tokenInfo?.email || '',
              address: {
                first_name: tokenInfo?.first_name || '',
                last_name: tokenInfo?.last_name || ''
              }
            });
            callGtagEvent(GOOGLE_CONVERSION_ID, GOOGLE_CONVERSION_TAG_STEP_2);
            callLinkedinTagEvent(LINKEDIN_CONVERSION_TAG_STEP_2);
            // eslint-disable-next-line no-empty
          } catch (e) {}

          return prevState;
        }
        const fetchData = async () => {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/company_registration/payments/estimate`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                data: {
                  company,
                  members
                }
              })
            }
          );

          const data = await response.json();
          setIsLoading(false);
          if (!data.error) {
            setPaymentInfo({
              ...paymentInfo,
              base: data.base,
              vat: data.vat,
              discount: data.discount,
              vat_percentage: data.vat_percentage,
              members: data.members,
              total: data.total_with_vat
            });
          } else {
            toast.error(data.message);
          }
        };

        fetchData();
      }

      if (prevState === 3) {
        setIsLoading(false);
        return prevState + 1;
      }

      if (prevState === 4) {
        const normalizedMembers: Member[] = [];
        members.forEach((member: any) => {
          const updatedMember = { ...member };
          updatedMember.type = [];
          if (updatedMember.shareholder) {
            updatedMember.type.push('shareholder');
          }
          if (updatedMember.board_member) {
            updatedMember.type.push('board_member');
          }
          if (!updatedMember.already_e_resident) {
            updatedMember.birthdate = dateToDDMMYYYYString(member.birthdate);
          }
          updatedMember.registration_id = uniqueIdForPerson(updatedMember);
          normalizedMembers.push(updatedMember);
          updatedMember.locale = locale;

          // gTag
          try {
            if (member.is_representative) {
              setGtagVariables({
                email: member.email,
                address: {
                  first_name: member.first_name,
                  last_name: member.last_name,
                  postal_code: member.address_zip,
                  street: member.address_street,
                  city: member.address_city,
                  country: member.address_country
                }
              });
            }
            callGtagEvent(GOOGLE_CONVERSION_ID, GOOGLE_CONVERSION_TAG_STEP_3);
            callLinkedinTagEvent(LINKEDIN_CONVERSION_TAG_STEP_3);
            // eslint-disable-next-line no-empty
          } catch (e) {}
        });

        const fetchData = async () => {
          const url = company.is_switch
            ? `${process.env.REACT_APP_API_URL}/auth/switch`
            : `${process.env.REACT_APP_API_URL}/auth/signup`;

          const response = await fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              data: {
                company: {
                  ...company,
                  activity_code: `EMTAK ${
                    isSwitch
                      ? company.activity_code
                      : emtak.find((emtakCode) => emtakCode.description === company.activity_code)
                          ?.code
                  }`,
                  name: company.company_name
                },
                members: normalizedMembers
              }
            })
          });

          const data = await response.json();
          setIsLoading(false);

          if (
            !data.error &&
            Object.prototype.hasOwnProperty.call(data, 'magic_link') &&
            isValidString(data.magic_link)
          ) {
            window.location.href = data.magic_link;
          } else {
            toast.error(
              data.message ||
                intl.formatMessage({
                  id: 'error_creating_company',
                  defaultMessage: 'An error occurred while creating the company.'
                })
            );
          }
        };
        fetchData();
      }

      if (prevState <= 2) {
        return prevState + 1;
      }

      return prevState;
    });
  };

  const handleStepChange = () => {
    return (
      <>
        {/* Registration step 0 -> Company name */}
        {step === 0 && (
          <CompanyType
            company={company}
            setCompany={setCompany}
            setStep={setStep}
            paymentInfo={paymentInfo}
            setPaymentInfo={setPaymentInfo}
          />
        )}
        {/* Registration step 1 -> Company name */}
        {step === 1 && !isSwitch && (
          <CompanyNameForm
            company={company}
            setCompany={setCompany}
            setStep={setStep}
            companyNameValidity={companyNameValidity}
            setCompanyNameValidity={setCompanyNameValidity}
            setShowConfetti={setShowConfetti}
            companyErrors={companyErrors}
            setCompanyErrors={setCompanyErrors}
            tokenInfo={tokenInfo}
          />
        )}
        {/* Switching Step 1 -> Company registry_number */}
        {step === 1 && isSwitch && (
          <CompanyCheckRegistry
            company={company}
            setCompany={setCompany}
            setMembers={setMembers}
            companyErrors={companyErrors}
            handleCompanyInputChange={handleCompanyInputChange}
            setStep={setStep}
            locale={locale}
          />
        )}
        {/* Registration Step 2 -> Company Info */}
        {step === 2 && !isSwitch && (
          <CompanyInfoForm
            company={company}
            handleCompanyInputChange={handleCompanyInputChange}
            companyErrors={companyErrors}
            newMember={newMember}
            handleNewMemberInputChange={handleNewMemberInputChange}
            handleNewMemberCheckboxChange={handleNewMemberCheckboxChange}
            handleClearNewMember={handleClearNewMember}
            newMemberErrors={newMemberErrors}
            handleMemberInputBirthDate={handleMemberInputBirthDate}
            members={members}
            membersError={membersError}
            handleAddUpdateMember={handleAddUpdateMember}
            handleRemoveMemberByIndex={handleRemoveMemberByIndex}
            handleShowMemberModal={handleShowMemberModal}
            showMemberModal={showMemberModal}
            modalAction={modalAction}
            handleCloseMemberModal={handleCloseMemberModal}
            handleValidateMemberFields={handleValidateMemberFields}
            handleAlreadyEResidentCheckboxChange={handleAlreadyEResidentCheckboxChange}
            handleIsRepresentativeCheckboxChange={handleIsRepresentativeCheckboxChange}
            locale={locale}
            handleUpdateShareCapitalContribution={handleUpdateShareCapitalContribution}
            handleAutocompleteChange={handleAutocompleteChange}
            selectedOption={selectedOption}
          />
        )}
        {/* Registration Step 1 -> Company Info */}
        {step === 2 && isSwitch && (
          <CompanyMissingInfo
            company={company}
            members={members}
            companyConfirmed={companyConfirmed}
            handleCompanyCheckboxChange={handleCompanyCheckboxChange}
            handleCompanyInputChange={handleCompanyInputChange}
            companyErrors={companyErrors}
            membersError={membersError}
            showMemberModal={showMemberModal}
            handleShowMemberModal={handleShowMemberModal}
            member={newMember}
            handleCloseMemberModal={handleCloseMemberModal}
            handleMemberInputChange={handleNewMemberInputChange}
            memberErrors={newMemberErrors}
            handleMemberCheckbox={handleNewMemberCheckboxChange}
            handleValidateMemberFields={handleValidateMemberFields}
            handleAddUpdateMember={handleAddUpdateMember}
            handleIsRepresentativeCheckboxChange={handleIsRepresentativeCheckboxChange}
            handleMemberCheckboxChange={handleNewMemberCheckboxChange}
            locale={locale}
          />
        )}
        {/* Registration Step 3 -> Company Terms */}
        {step === 3 && (
            <CompanyTermsConfirmation
                handleCompanyTermsCheckboxChange={handleCompanyTermsCheckboxChange}
                companyTermsCheckboxes={companyTermsCheckboxes}
            />
        )}
        {step === 4 && (
          <PaymentForm
            paymentInfo={paymentInfo}
            handlePaymentInfoCheckboxChange={handlePaymentInfoCheckboxChange}
            isSwitch={isSwitch}
            company={company}
          />
        )}
      </>
    );
  };

  return (
    <div className="steps-container">
      {showConfetti && <Confetti />}
      <Box className="steps-step-container w-1/2 md:w-3/5 md:relative md:-top-6 md:pt-16 md:px-20 md:pb-12">
        <Box
          maxWidth="sm"
          className="flex-col justify-between items-center min-h-full min-w-full"
          sx={{ display: 'flex!important' }}>
          {/* desktop */}
          <Box
            className="hidden md:flex items-center justify-between"
            sx={{ maxWidth: '650px !important', width: '100%' }}>
            <a
              target="_blank"
              rel="noreferrer"
              href={locale === 'es' ? 'https://companio.co/es/' : 'https://companio.co/'}>
              <img
                src="/assets/images/companio-logo.png"
                width="123"
                alt="logo"
                style={{ maxHeight: '30px' }}
              />
            </a>

            <div className="flex items-center">
              <p className="text-gray-400 text-xs">
                <FormattedMessage id="having_trouble" defaultMessage="Having trouble?" />{' '}
                <a
                  href={
                    locale === 'es'
                      ? 'https://help.companio.co/es/category/alta-de-empresa-ji0jsi/'
                      : 'https://help.companio.co/en/category/set-up-11u7rt/'
                  }
                  target="_blank"
                  style={{ fontWeight: 500, color: '#253292' }}
                  rel="noreferrer">
                  <FormattedMessage id="get_help" defaultMessage="Get help." />
                </a>
              </p>
              <img className="ml-1" src="/assets/icons/info.svg" alt="info" />
            </div>
          </Box>
          {/* mobile */}
          <Box className="flex md:hidden w-full justify-between">
            <ArrowBackIosNewIcon
              className={step === 1 ? 'invisible' : 'visible'}
              onClick={handlePreviousStep}
            />
            <RegistrationStepperMobile currentStep={step ? step - 1 : step} />
            <a
              href={
                locale === 'es'
                  ? 'https://help.companio.co/es/category/alta-de-empresa-ji0jsi/'
                  : 'https://help.companio.co/en/category/set-up-11u7rt/'
              }
              target="_blank"
              style={{ fontWeight: 500, color: '#253292' }}
              rel="noreferrer">
              <img src="/assets/icons/info.svg" alt="info" />
            </a>
          </Box>

          {handleStepChange()}
          {step > 1 ? (
            <div
              className="w-full items-center md:justify-between md:flex md:mt-5 md:mb-5"
              style={{ maxWidth: '650px' }}>
              <RegistrationButton
                disabled={isLoading}
                className="invisible md:visible registration-button-w w-full md:mr-3 md:p1"
                onClick={handlePreviousStep}
                id="previous_step_btn"
                variant="outlined"
                size="large"
                direction="back">
                <FormattedMessage id="previous_step" defaultMessage="Previous step" />
              </RegistrationButton>
              <RegistrationButton
                className="registration-button-w md:p10"
                onClick={() => {
                  setIsLoading(true);
                  handleNextStep();
                }}
                id="next_step_btn"
                variant="filled"
                size="large"
                direction="forward"
                disabled={handleAllFieldsFullFilled()}>
                {NextStepButtonTxt(currentStates, intl as IntlShape)}
              </RegistrationButton>
            </div>
          ) : (
            <div />
          )}
        </Box>
      </Box>
      <Box
        className="steps-step-container md:fixed top-0 right-0 w-1/2 hidden md:flex md:flex-col md:items-center md:justify-between md:w-2/5"
        style={{
          background: isSwitch
            ? 'linear-gradient(109.4deg, #018EFF 1.26%, #9A53CB 96.4%)'
            : 'linear-gradient(109.4deg, #FF4EA1 1.26%, #FEA500 96.4%)',
          padding: '40px'
        }}>
        <div className="hidden md:block" />
        <img className="md:hidden" src="/assets/images/companio-logo-white.png" alt="white-logo" />
        <RegistrationStepper currentStep={step ? step - 1 : step} isSwitch={isSwitch} />
        <p style={{ fontSize: '14px', color: 'white' }}>
          © CompanioEstonia OÜ. All rights reserved.
        </p>
      </Box>
    </div>
  );
}

export default withErrorBoundary(Index, {
  fallback: <div>Failed to load</div>
});
